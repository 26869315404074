<template>
  <ValidationObserver
    ref="form"
    tag="div"
    v-slot="{ handleSubmit }"
    class="container"
  >
    <form @submit.prevent="handleSubmit(handleLogin)" class="form-container">
      <h2>
        Hola,<br />
        &iexcl;Bienvenido Admin ELHA Digital Feedback!
      </h2>
      <p>
        Ingresa las credenciales que se te proporcionaron para empezar a
        administrar tus encuestas.
      </p>

      <custom-input
        v-model="email"
        vid="email"
        id="email"
        type="text"
        label="Correo"
        rules="required"
      />
      <custom-input
        v-model="password"
        vid="password"
        id="password"
        type="password"
        label="Contraseña"
        rules="required"
      />

      <custom-button type="submit" variant="primary" :disabled="loading">
        {{ loading ? "Cargando..." : "Iniciar Sesión" }}
      </custom-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";

import CustomButton from "../components/CustomButton.vue";
import CustomInput from "../components/CustomInput.vue";

import { ValidationObserver } from "vee-validate";

export default {
  name: "Login",

  components: {
    CustomButton,
    CustomInput,
    ValidationObserver
  },

  data() {
    return {
      email: "",
      password: "",
      loading: false
    };
  },

  computed: {
    ...mapState(["userProfile"])
  },

  methods: {
    ...mapActions(["login"]),

    handleLogin() {
      this.loading = true;

      this.login({
        email: `${this.email}`,
        password: this.password
      }).catch(e => {
        console.log(e);
        this.loading = false;
        this.setErrorMessage(e);
      });
    },

    setErrorMessage(error) {
      console.log(error);
      switch (error.code) {
        case "auth/wrong-password":
          this.$refs.form.setErrors({
            password: ["La contraseña ingresada es incorrecta."]
          });
          break;

        case "auth/user-not-found":
          this.$refs.form.setErrors({
            email: ["El usuario ingresado no fue encontrado."]
          });
          break;

        case "auth/too-many-requests":
          this.$refs.form.setErrors({
            password: [
              "Se han producido demasiados intentos fallidos, por favor inténtelo nuevamente más tarde."
            ]
          });
          break;

        default:
          this.$refs.form.setErrors({
            password: ["Ocurrio un error, por favor inténtalo de nuevo."]
          });
          break;
      }
    }
  }
};
</script>

<style scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2,
p {
  text-align: center;
}

.form-container {
  max-width: 480px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: var(--lengthMd1);
  margin: 0 auto;
}
</style>
